home-element {

	section.hero {
		background: -webkit-linear-gradient(#d6d6d6 0%, #eaeaea 47%, #ffffff 100%);
		background: -o-linear-gradient(#d6d6d6 0%, #eaeaea 47%, #ffffff 100%);
		background: linear-gradient(#d6d6d6 0%, #eaeaea 47%, #ffffff 100%);
		height: 100vh;
		width: 100vw;
		display: block;
		margin: 0;

		.val-prop {
			position: relative;
			top: 25%;
			transform: translateY(-25%);
			text-align: center;
			width: 60%;
			margin: auto;

			h1 {
				font-family: Ultra;
				font-size: 72px;
				margin-bottom: 25px;
				text-shadow: 2px 2px #b7b7b7;
				transition: transform .8s 1s, opacity .8s 1s;
				opacity: 0;
				transform: translate(0, -15px);
			}

			h2 {
				font-family: heartandsoul;
				color: #C30202;
				font-size: 100px;
				top: 25px;
				z-index: 1;
				transition: transform .8s 1s, opacity .8s 1s;
				opacity: 0;
				transform: translate(0, -15px);
			}

			hr {
				width: 0;
				margin: auto;
				margin-bottom: 25px;
				transition: width 1.5s 1.25s;
			}

			p {
				font-family: proximanova;
				color: #666;
				font-size: 24px;
				line-height: 1.6;
				margin-bottom: 30px;
			}

			input {
				font-family: Ultra;
				font-size: 24px;
				padding: 1%;
				color: #666;
				border: none;
				border-radius: 5px;
				text-align: center;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				display: inline-block;
				height: 50px;
				width: 125px;
			}

			.button {
				font-family: proximanova;
				display: inline-block;
				width: 50px;
				height: 50px;
				vertical-align: top;
				color: white;
				border-top-right-radius: 5px;
				border-bottom-right-radius: 5px;
				background-color: #c30202;
				font-size: 20px;
				line-height: 2.4;
				cursor: pointer;
				transition: background-color .3s;

				&:hover {
					background-color: darken(#c30202, 10%);
				}
			}

			.play {
				margin-top: 4%;
				width: 125px;
				height: 125px;
				cursor: pointer;
				opacity: 0;
				transition: opacity .3s, opacity .8s 3s;

				&:hover {
					opacity: .7;
				}
			}
		}

		img.gavel {
			left: 50%;
			top: 95%;
			transform: translate(-50%, -95%);
			position: absolute;
			opacity: 0;
			transition: opacity 2s 2.5s;
		}

		@media (max-width: 950px) {

			img.gavel {
				height: 250px;
			}

			.val-prop {
				h2 {
					font-size: 50px;
				}

				h1 {
					font-size: 50px;
				}

				p {
					font-size: 20px;
				}

				input {
					height: 40px;
					font-size: 20px;
				}

				.button {
					height: 40px;
					font-size: 18px;
				}

				.play {
					width: 100px;
					height: 100px;
				}
			}
		}

		@media (max-width: 500px) {
			img.gavel {
				height: 250px;
			}

			.val-prop {
				h2 {
					font-size: 40px;
					top: 10px;
				}

				h1 {
					font-size: 40px;
				}

				p {
					font-size: 20px;
					line-height: 1;
				}

				input {
					height: 40px;
					font-size: 20px;
				}

				.button {
					height: 40px;
					font-size: 18px;
				}
			}
		}

		@media (max-height: 850px) {

			.val-prop .play {
				width: 100px;
				height: 100px;
			}
		}

		@media (max-height: 768px) {
			img.gavel {
				height: 200px;
			}

			.val-prop {
				h2 {
					font-size: 50px;
				}

				h1 {
					font-size: 50px;
				}

				p {
					font-size: 20px;
				}

				input {
					height: 40px;
					font-size: 20px;
				}

				.button {
					height: 40px;
					font-size: 18px;
				}
			}
		}

		@media (max-height: 700px) {
			img.gavel {
				height: 150px;
			}

			.val-prop {
				width: 90%;
				h2 {
					font-size: 30px;
					top: 5px;
				}

				h1 {
					font-size: 30px;
				}

				p {
					font-size: 16px;
				}

				input {
					height: 40px;
					font-size: 20px;
				}

				.button {
					height: 40px;
					font-size: 18px;
				}
			}
		}

		@media (max-height: 450px) {
			img.gavel {
				display: none;
			}

			.val-prop {
				top: 40%;

				h2 {
					top: 5px;
				}
			}
		}

		&.is-ready {

			h2 {
				opacity: 1;
				transform: translate(0,0);
			}
			h1 {
				opacity: 1;
				transform: translate(0,0);
			}

			hr {
				width: 50%;
			}

			.play {

				opacity: 1;
			}

			img.gavel {
				opacity: 1;
			}
		}
	}

	section.summary {
		padding-top: 100px;
		background: linear-gradient(#ffffff 0%,  #bdbdbd 100%);
		overflow-x: hidden;

		h1 {
			top: -30px;
			text-align: center;
			font-size: 50px;
			font-family: ultra;
			margin-bottom: 50px;
		}

		h2 {
			font-family: heartandsoul;
			font-size: 42px;
			text-align: center;
			top: -8px;
			color: #c40a0a;
			z-index: 1;
			letter-spacing: 1px;
			word-spacing: 5px;
		}

		.content.mission {
			border-left: 15px solid #404040;
			border-right: 15px solid #404040;
			border-bottom: 15px solid #404040;
			padding-bottom: 75px;
			display: block;

			img.badge {
				width: 140px;
				top: -63px;
				margin-right: 40px;
				margin-left: 40px;

				@media (max-width: 500px) {
					width: 100px;
					top: -47px;
				}
			}

			.border-topright, .border-topleft {
				width: calc(50% - 110px);
				height: 17px;
				background-color: #404040;
				display: inline-block;
				top: -84px;
				top: -123px;

				@media (max-width: 500px) {
					height: 10px;
					width: calc(50% - 90px);
					top: -90px;
				}
			}

			.border-topright {
				right: -1px;
			}

			p {
				margin-left: 50px;
				margin-right: 50px;
				font-size: 22px;
				line-height: 1.6;
				font-family: proximanova;
				color: #666;
				text-align: justify;
				top: -30px;
				margin-bottom: 25px;
			}

			.goals {
				display: flex;
				justify-content: center;
				width: 90%;
				margin: auto;

				.goal {
					width: 90%;
					text-align: center;

					img {
						width: 75px;
						height: 118px;
						margin-bottom: 20px;

						&.map {
							width: 120px;
						}

						@media (max-width: 500px) {
							width: 40px;
							height: 63px;
						}
					}

					p {
						top: auto;
						width: 90%;
						margin: auto;
						font-size: 22px;

						b {
							color: #C30202;
						}
					}
				}
			}

			@media (max-width: 900px) {
				.goals {
					flex-direction: column;

					.goal {
						margin-top: 25px;
						margin-bottom: 50px;

						&.inspire {
							margin-top: 0;
						} 
					}
				}
			}

			@media (max-width: 700px) {
				p {
					margin-left: 5%;
					margin-right: 5%;
					font-size: 20px;
				}

				.goals .goal p {
					font-size: 20px;
				}
			}

			@media (max-width: 500px) {
				border-left: 10px solid #404040;
				border-right: 10px solid #404040;
				border-bottom: 10px solid #404040;
				margin: 0;
				width: 100%;
				padding-bottom: 5%;

				p {
					font-size: 17px;
				}

				.goals .goal {
					width: 100%;
					margin-bottom: 0;
				}
				.goals .goal p {
					font-size: 17px;
					margin: 0;
					width: 100%;
				}
			}
		}

		.content.research {
			display: flex;

			.left {
				width: 33%;
				min-height: 100px;
				padding-right: 5%;
				padding-top: 100px;

				h2 {
					top: 0;
				}

				h1 {
					margin-bottom: 0;
					color: black;
				}

				p {
					font-size: 24px;
					color: #666;
					font-family: proximanova;
					line-height: 1.6;
					text-align: justify;
				}
			}

			.right {
				width: 67%;
				text-align: center;

				img.gear {
					width: 100%;
					margin-left: 20%;
					top: -100px;
				}

				.statistics {
					display: flex;
					flex-wrap: wrap;
					border-left: 1px solid #666666;
					padding-left: 5%;
					text-align: left;
					top: -100px;

					.statistic {
						width: 32%;
						margin-bottom: 5%;
						margin-top: 5%;
					}

					&:before {
						content: "";
						width: 10px;
						height: 10px;
						border: solid 1px #666666;
						display: block;
						border-radius: 10px;
						left: -6%;
						top: -9px;
					}

					&:after {
						content: "";
						display: block;
						position: absolute;
						left: -6px;
						bottom: -9px;
						width: 10px;
						height: 10px;
						border: solid 1px #666666;
						display: block;
						border-radius: 10px;
					}
				}

				h3 {
					font-family: Ultra;
					font-size: 50px;
					color: #666;

					img {
						height: 23px;
						width: 23px;
						margin-left: 5px;
						top: -15px;
					}
				}

				h4 {
					font-family: proximanova;
					text-transform: uppercase;
					color: lighten(#666, 10%);
					font-size: 18px;
				}
			}

			@media (max-width: 1150px) {
				flex-direction: column;

				.left {
					width: 100%;
					padding-right: 0;
				}

				.right {
					width: 80%;
					margin-right: 10%;
					margin-left: 10%;

					img.gear {
						display: none;
					}

					.statistics {
						top: 0;
						padding: 0;
						border: none;

						&:before, &:after {
							display: none;
						}
					}
				}
			}

			@media (max-width: 1000px) {

				.right {

					h3 {
						font-size: 35px;

						img {
							height: 16px;
							width: 16px;
						}
					}

					h4 {
						font-size: 14px;
					}
				}
			}

			@media (max-width: 700px) {
				.left {

					p {
						font-size: 20px;
					}
				}

				.right {

					.statistics {
						margin-top: 50px;

						.statistic {
							width: 50%;
						}
					}
				}
			}

			@media (max-width: 600px) {
				.left h2 {
					top: -15px;
				}
			}

			@media (max-width: 500px) {
				.left {

					p {
						font-size: 17px;
					}
				}
			}

			@media (max-width: 450px) {

				.right {

					.statistics {
						text-align: center;

						.statistic {
							width: 100%;
						}
					}
				}
			}
		}

		.content.context {
			padding-bottom: 150px;
			padding-top: 75px;
			display: block;

			h1 {
				margin-bottom: 0;
			}

			p {
				font-family: proximanova;
				font-size: 22px;
				line-height: 1.6;
				width: 85%;
				margin: auto;
				margin-bottom: 20px;
				color: #666666;
				text-align: justify;
			}

			@media (max-width: 600px) {
				padding-bottom: 75px;

				p {
					font-size: 20px;
					width: 100%;
				}
			}

			@media (max-width: 500px) {
				padding-bottom: 50px;

				p {
					font-size: 17px;
				}
			}
		}

		@media (max-width: 1250px) {
			padding-right: 5%;
			padding-left: 5%;
		}

		@media (max-width: 600px) {
			h1 {
				font-size: 35px;
			}

			h2 {
				top: -13px;
				font-size: 35px;
			}
		}
	}

	section.personas {
		background: linear-gradient(#bdbdbd 0%, #868686 100%);
		padding-bottom: 100px;

		h1 {
			top: -30px;
			text-align: center;
			font-size: 50px;
			font-family: ultra;
		}

		h2 {
			font-family: heartandsoul;
			font-size: 42px;
			text-align: center;
			top: -8px;
			color: #c40a0a;
			z-index: 1;
			letter-spacing: 1px;
			word-spacing: 5px;
		}

		p {
			max-width: 1000px;
			margin: auto;
			font-size: 20px;
			font-family: proximanova;
			line-height: 1.6;
			color: #333;
			margin-bottom: 50px;
			min-height: 200px;
		}

		.content {
			display: flex;
			justify-content: center;

			.persona {
				width: 22%;
				margin: 1%;
				min-height: 70px;
				border: solid 5px #404040;
				padding: 2%;
				padding-top: 1%;
				position: relative;

				cursor: pointer;
				transition: background 1s ease-out; 
				display: inline-block;
				position: relative;
				background-color: #ccc;
				background-image: repeating-radial-gradient(ellipse farthest-corner at left bottom, rgb(255, 253, 253) 0%, rgba(222, 222, 222, 0.92) 100%);
				-webkit-backface-visibility: hidden;
				z-index: 1;

				.icon {
					background-color: #404040;
					width: 60px;
					height: 60px;
					border-radius: 60px;
					position: absolute;
					top: -10%;
					left: 50%;
					transform: translateX(-50%);
					box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.53);
					background-size: 50%;
					background-repeat: no-repeat;
					background-position: center;
				}

				h1 {
					top: 0;
					font-family: Ultra;
					text-align: center;
					font-size: 30px;
					margin-bottom: 5%;
					border-bottom: 1px solid black;
					line-height: 2;
				}

				p {
					font-family: proximanova;
					font-size: 16px;
					line-height: 1.6;
					color: #333;
					text-align: justify;
					margin-bottom: 10%;
				}

				.button {
					font-family: proximanova;
					text-transform: uppercase;
					padding: 5%;
					border: solid #333 1px;
					text-align: center;
					border-radius: 5px;
					color: #333;
					cursor: pointer;
					transition: all .3s;

					&:hover {
						background-color: rgba(0, 0, 0, 0.08);
					}
				}

				&.public {

					.icon {
						background-image: url(../assets/public.svg);
					}

					&:after {
						background-image: repeating-radial-gradient(ellipse farthest-corner at left bottom, #f9ddde 0%, #c5a3be 100%);
					}
				}

				&.clerks {

					.icon {
						background-image: url(../assets/clerk.svg);
					}

					&:after {
						background-image: repeating-radial-gradient(ellipse farthest-corner at left bottom, #cafafd 0%, #8caeb5 100%);
					}
				}

				&.judges {

					.icon {
						background-image: url(../assets/judge.svg);
						background-size: 70%;
					}

					&:after {
						background-image: repeating-radial-gradient(ellipse farthest-corner at left bottom, #fffebe 0%, #93d6cd 100%);
					}
				}

				&.admin {

					.icon {
						background-image: url(../assets/admin.svg);
						background-size: 70%;
					}

					&:after {
						background-image: repeating-radial-gradient(ellipse farthest-corner at left bottom, #fddeb6 0%, #c3a47b 100%);
					}
				}

				&:after {
					position: absolute;
					content: '';
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-image: repeating-radial-gradient(ellipse farthest-corner at left bottom, #fddeb6 0%, #c3a47b 100%);
					transition: opacity 0.5s ease-out;
					z-index: 2;
					opacity: 0;
				}

				&:hover:after {
					opacity: 1;
				}

				.icon, h1, p, .button {
					z-index: 3;
				}
			}
		}

		&:before {
			content: "";
			background-image: url(../assets/circle.png);
			width: 100%;
			height: 79%;
			display: block;
			position: absolute;
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center bottom;
			top: 144px;
		}

		@media (max-width: 1100px) {

			&:before {
				display: none;
			}

			p {
				width: 80%;
				min-height : auto;
				text-align: justify;
			}

			.content {
				flex-direction: column;
				align-items: center;

				.persona {
					width: 80%;
					text-align: center;
					margin-bottom: 50px;

					.icon {
						top: -20%;
					}

					h1 {
						margin-bottom: 1%;
					}

					p {
						min-height : auto;
						margin-bottom: 3%;
						width: 100%;
					}

					.button {
						padding: 1%;
						width: auto;
						display: inline-block;
						margin-bottom: 1%;
					}
				}
			}
		}

		@media (max-width: 600px) {
			padding-bottom: 0;

			p {
				font-size: 18px;
			}

			h1 {
				top: 0;
				margin-bottom: 30px;
				font-size: 35px;
			}

			h2 {
				top: 24px;
				font-size: 35px; 
			}
		}

		@media (max-width: 500px) {
			p {
				font-size: 17px;
			}
			.content .persona {
				width: 100%;
			}
		}
	}

	section.roi {
		background: linear-gradient(#868686 0%, #000000 100%);
		min-height: 1000px;
		overflow-x: hidden;
		padding-top: 100px;
		padding-bottom: 100px;

		.content {
			display: flex;

			.left {
				width: 50%;
				min-height: 50px;

				img.box {
					width: 250%;
					left: -80%;
				}
			}

			.right {
				width: 50%;
				min-height: 50px;
				padding-top: 100px;

				h1 {
					top: -30px;
					text-align: center;
					font-size: 50px;
					font-family: ultra;
				}

				h2 {
					font-family: heartandsoul;
					font-size: 42px;
					text-align: center;
					top: -8px;
					color: #c40a0a;
					z-index: 1;
					letter-spacing: 1px;
					word-spacing: 5px;
				}

				p {
					font-size: 22px;
					line-height: 1.6;
					font-family: proximanova;
					color: #fff;
					text-align: justify;
					margin-bottom: 25px;
				}
			}

			&.returns {
				display: block;

				.rois {
					display: flex;
					width: 80%;
					margin: auto;
					margin-top: 100px;

					.icon {
						text-align: center;
						height: 50px;
						width: 50px;
						background-size: contain;
						background-repeat: no-repeat;
						background-position: center;
						display: block;

						&.architecture {
							background-image: url(../assets/architect.svg);
						}

						&.code {
							background-image: url(../assets/code.svg);
						}

						&.database {
							background-image: url(../assets/database.svg);
						}

						&.paintbrush {
							background-image: url(../assets/paintbrush.svg);
						}

						&.processes {
							background-image: url(../assets/process.svg);
						}
					}

					.return {
						margin-left: 2%;
						width: calc(100% - 150px);

						h1 {
							color: darken(white, 40%);
							font-family: ultra;
							color: #999;
							font-size: 35px;
							font-weight: normal;
							letter-spacing: 2px;
							margin-bottom: 10px;
						}

						p {
							color: white;
							font-family: proximanova;
							font-size: 20px;
							line-height: 1.6;
							text-align: justify;

							span {
								color: #d8d8d8;
								text-transform: uppercase;
								font-size: 13px;
								margin-left: 10px;
								cursor: pointer;
								transition: color .3s;
								border: solid #d8d8d8 1px;
								padding: 2px;
								padding-left: 5px;
								padding-right: 5px;
								border-radius: 5px;
								transition: all .3s;
								letter-spacing: 1px;

								&:hover {
									background-color: #8c8c8c;
								}
							}
						}

						.bullets {
							//display: none;
							max-height: 0;
							min-height: 0;
							overflow: hidden;
							transition: all 1s;

							h2 {
								color: #fdc4c4;
								margin-top: 40px;
								font-family: Ultra;
								text-transform: uppercase;
								font-size: 22px;
								letter-spacing: 2px;
								margin-left: 5%;
								margin-bottom: 8px;
							}

							p {
								color: darken(white, 25%);
								font-size: 20px;
								margin-left: 5%;
							}

							&.is-open {
								max-height: 1500px;
							}
						}
					}
				}
			}
		}

		@media (max-width: 1250px) {
			.content .right {
				padding: 5%;
			}
		}

		@media (max-width: 900px) {
			padding-top: 0;

			.content {

				.right {

					h1 {
						font-size: 35px;
						top: 0;
						margin-bottom: 20px;
					}

					h2 {
						font-size: 35px;
						top: 15px;
					}

					p {
						font-size: 18px;
						line-height: 1.4;
					}
				}
			}
		}

		@media (max-width: 750px) {

			.content {

				.left {
					display: none;
				}

				.right {
					width: 90%;
					margin-right: 5%;
					margin-left: 5%;
					padding: 0;
					margin-bottom: 35px;

					h1 {
						font-size: 50px;
					}

					h2 {
						font-size: 45px;
					}
				}
			}

			.content.returns {

				.rois:first-child {
					margin-top: 0;
				}

				.rois {
					width: 90%;
					margin-right: 5%;
					margin-left: 5%;

					.icon {
						height: 35px;
						width: 35px;
					}

					.return {
						width: 100%;

						h1 {
							font-size: 26px;
						}

						p {
							font-size: 18px;

							span {
								display: inline-block;
							}
						}

						.bullets h2 {
							font-size: 18px;
							margin-left: 0;
						}

						.bullets p {
							font-size: 18px;
							margin-left: 0;
						}
					}
				}
			}
		}

		@media (max-width: 600px) {

			.content {

				.right {

					h2 {
						font-size: 35px;
					}

					h1 {
						font-size: 35px;
					}
				}
			}
		}

		@media (max-width: 500px) {

			.content.returns .rois .return .bullets p {
				font-size: 15px;
			}

			.content.returns .rois .return p {
				font-size: 17px;
			}

			.content {

				.right {

					p {
						font-size: 17px;
					}
				}
			}
		}
	}

	footer {
		min-height: 200px;
		background-color: black;
		padding-bottom: 50px;
		padding-top: 100px;

		h1.main {
			top: -30px;
			text-align: center;
			font-size: 50px;
			font-family: ultra;
		}

		h2.sub {
			font-family: heartandsoul;
			font-size: 42px;
			text-align: center;
			top: -8px;
			color: #c40a0a;
			z-index: 1;
			letter-spacing: 1px;
			word-spacing: 5px;
		}

		.content {
			display: flex;
			flex-wrap: wrap;

			.contributor {
				width: 50%;
				padding: 10px;
				padding-left: 20px;
				padding-right: 20px;
				margin-bottom: 20px;
				display: flex;
			}
		}

		h1 {
			font-size: 20px;
			font-family: ultra;
			color: #666;
			margin-bottom: 5px;
		}

		p {
			font-size: 15px;
			color: #555;
			font-family: proximanova;
			line-height: 1.4;
			text-align: justify;
		}

		.avatar {
			width: 60px;
			height: 60px;
			margin-right: 25px;
		}
	}
}