slideshow-element {
	position: absolute;
	top: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.66);
	z-index: 2;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	.slideshow-container {
		max-width: 1000px;
		position: relative;
		margin: auto;
		background-color: white;

		@media (max-width: 1200px) {
			width: 80%;
		}


		@media (max-width: 500px) {
			width: 75%;
		}
	}


/* Next & previous buttons */
	.prev,
	.next {
		cursor: pointer;
		position: absolute;
		top: 50%;
		transition: 0.3s ease;
		height: 100px;
		width: 100px;
		transform: translateY(-50%);

		@media (max-width: 500px) {
			height: 50px;
			width: 50px;
		}
	}

	.prev {
		left: -71px;
		@media (max-width: 500px) {
			left: -35px;
		}
	}

	.next {
		right: -75px;
		transform: rotate(180deg) translateY(75%);
		@media (max-width: 500px) {
			right: -35px;
		}
	}

.close {
	position: absolute;
	width: 70px;
	height: 70px;
	z-index: 5;
	right: -33px;
	top: -26px;
	cursor: pointer;
	transition: 0.3s ease;

	@media (max-width: 500px) {
		width: 45px;
		height: 45px;
		right: -22px;
		top: -17px;
	}

	&:hover {
		opacity: .5;
	}
}

/* On hover, add a black background color with a little bit see-through */
	.prev:hover,
	.next:hover {
		opacity: .5;
	}

/* Number text (1/3 etc) */
	.numbertext {
		color: #666;
		font-size: 12px;
		padding: 8px 12px;
		position: absolute;
		top: 0;
		z-index: 3;
	}


/* The dots/bullets/indicators */
	.dots {
		margin-top: 15px;
		text-align: center;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
	}
	.dot {
		cursor: pointer;
		height: 13px;
		width: 13px;
		margin: 0 2px;
		background-color: #bbb;
		border-radius: 50%;
		display: inline-block;
		transition: background-color 0.6s ease;
		@media (max-width: 500px) {
			height: 8px;
			width: 8px;
		}
	}

	.active,
	.dot:hover {
		background-color: #717171;
	}


	/* Fading animation */

	.fade {
		-webkit-animation-name: fade;
		-webkit-animation-duration: 1s;
		animation-name: fade;
		animation-duration: 1s;
	}
}


@-webkit-keyframes fade {
	from {
		opacity: .4
	}
	to {
		opacity: 1
	}
}

@keyframes fade {
	from {
		opacity: .4
	}
	to {
		opacity: 1
	}
}