judge-element {
	display: block;
	background-color: #1d0304;
	padding-bottom: 300px;

	@media (max-width: 600px) {
		.content .hero {
			margin-bottom: 0;
		}
	}
	section.hero {
		background-color: #1d0304;
		background-image: url(../assets/judge_hero.jpg);
		margin-bottom: 0;

		.left ul li{
			color: #e47d7d;

			&:hover {
				color: darken(#e47d7d, 15%);
			}
		}
	}

	section.dashboard {
		background: radial-gradient(ellipse at center, #8a4a4a 0%, rgba(125, 185, 232, 0) 69%);
		margin-top: 200px;
		overflow: hidden;

		@media (max-width: 1000px) {
			img.webappui {
				width: 90%;
				height: 90%;
				margin: auto;
			}
		}

		@media (max-width: 600px) {
			margin-top: 50px;
		}
	}

	section.remote {
		padding-top: 100px;
		background: radial-gradient(ellipse at center, #8a4a4a 0%, rgba(125, 185, 232, 0) 69%);

		img.video {
			height: 400px;
		}

		@media (max-width: 1000px) {
			img.video {
				width: 90%;
				height: 90%;
				margin: auto;
			}
		}

		@media (max-width: 600px) {
			margin-top: 50px;
		}
	}

	section.async {
		padding-top: 200px;
		background: radial-gradient(ellipse at center, #8a4a4a 0%, rgba(125, 185, 232, 0) 69%);

		img.arrows {
			height: 400px;
		}

		@media (max-width: 600px) {
			padding-top: 100px;
		}

		@media (max-width: 1000px) {
			img.arrows {
				width: 60%;
				height: 60%;
				margin: auto;
				margin-bottom: 25px;
			}
		}
	}

	section.casenotes {
		padding-top: 200px;
		background: radial-gradient(ellipse at center, #8a4a4a 0%, rgba(125, 185, 232, 0) 69%);

		img.notepad {
			height: 400px;
		}

		@media (max-width: 1000px) {
			img.notepad {
				width: 60%;
				height: 60%;
				margin: auto;
				margin-bottom: 25px;
			}
		}

		@media (max-width: 600px) {
			padding-top: 50px;
		}
	}

	@media (max-width: 600px) {
		padding-bottom: 50px;
	}
}