topnav-element {
	position: absolute;
	top:0;
	left: 0;
	width: 100vw;
	z-index: 2;

	ul {
		display: flex;
		justify-content: center;
		max-width: 800px;
		margin: auto;

		li {
			text-align: center;
			font-family: proximanova;
			text-transform: uppercase;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #000;
			font-size: 18px;
			width: 175px;
			cursor: pointer;
			transition: color .3s, transform .8s 3s, opacity .8s 3s;
			opacity: 0;
			transform: translate(0, -15px);

			&.logo {
				background-color: #c30202;
				width: 100px;
				min-width: 100px;
				height: 100px;
				border-radius: 100px;
				box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.53);
				margin-left: 5%;
				margin-right: 5%;
				margin-top: 2%;
				transition: color .3s, transform .8s 2s, opacity .8s 2s, background-color .3s;

				img {
					width: 60px;
					height: 50px;
					top: -3px;
					@media (max-height: 768px) {
						width: 40px;
						height: 40px;
					}
					@media (max-width: 768px) {
						width: 40px;
						height: 40px;
					}
				}

				&:hover {
					background-color: darken(#c30202, 3%);
				}

				@media (max-height: 768px) {
					width: 60px;
					min-width: 60px;
					height: 60px;
					border-radius: 60px;
				}

				@media (max-width: 768px) {
					width: 60px;
					min-width: 60px;
					height: 60px;
					border-radius: 60px;
				}
			}

			&:hover {
				color: #c30202;
			}

			&.is-ready {
				transform: translate(0, 0);
				opacity: 1;
			}

			@media (max-width: 450px) {
				font-size: 14px!important;
			}
		}

		@media (max-width: 800px) {

			li {
				width: 20%;
				font-size: 16px;

				&.logo {
					margin-left: 1%;
					margin-right: 1%;
				}
			}
		}
	}

	@media (max-width: 800px) {
		width: 90vw;
		margin-left: 5%;
		margin-right: 5%;
	}
	@media (max-width: 600px) {
		margin: 0;
		width: 100vw;
	}
}

body[page="home"] {
	topnav-element ul li {
		color: #666;

		&:hover {
			color: #c30202;
		}
	}
}

body[page="public"] {
	topnav-element ul li {
		color: #fff;

		&:nth-child(4) {
			color: black;
		}

		&:nth-child(5) {
			color: black;
		}

		&:hover {
			color: grey;
		}
	}
}

body[page="clerks"] {
	topnav-element ul li {
		color: #fff;

		&:hover {
			color: grey;
		}
	}
}

body[page="judge"] {
	topnav-element ul li {
		color: #fff;

		&:hover {
			color: grey;
		}
	}
}

body[page="admin"] {
	topnav-element ul li {
		color: #fff;

		&:hover {
			color: grey;
		}
	}
}