/*--------------------------------------------------------*\

	*Filename:		sass.scss
	*Description: 	contains SASS variables and mixins
	*Version:		1.0.0(2015-03-10)
	*Website:		http://schonne.com
	*Author:		Schonne Eldridge

\*---------------------------------------------------------*/

@mixin accelerate {
	-webkit-transform: translate3d(0, 0, 0);
	-webkit-backface-visibility: hidden;
	-webkit-perspective: 1000px;
	        transform: translate3d(0, 0, 0);
	        backface-visibility: hidden;
}

.shake {
	        animation: shake 150ms 2 linear;
	   -moz-animation: shake 150ms 2 linear;
	-webkit-animation: shake 150ms 2 linear;
	     -o-animation: shake 150ms 2 linear;
}

@keyframes shake {
	0% {
		transform: translate(10px, 0);
	}
	50% {
		transform: translate(-10px, 0);
	}
	100% {
		transform: translate(0, 0);
	}
}

@-moz-keyframes shake {
	0% {
		-moz-transform: translate(10px, 0);
	}
	50% {
		-moz-transform: translate(-10px, 0);
	}
	100% {
		-moz-transform: translate(0, 0);
	}
}

@-webkit-keyframes shake {
	0% {
		-webkit-transform: translate(10px, 0);
	}
	50% {
		-webkit-transform: translate(-10px, 0);
	}
	100% {
		-webkit-transform: translate(0, 0);
	}
}

@-ms-keyframes shake {
	0% {
		-ms-transform: translate(10px, 0);
	}
	50% {
		-ms-transform: translate(-10px, 0);
	}
	100% {
		-ms-transform: translate(0, 0);
	}
}

@-o-keyframes shake {
	0% {
		-o-transform: translate(10px, 0);
	}
	50% {
		-o-transform: translate(-10px, 0);
	}
	100% {
		-o-transform: translate(0, 0);
	}
}