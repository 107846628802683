public-element {
	display: block;
	background-color: #0b0a1c;
	padding-bottom: 300px;

	section.hero {
		background-image: url(../assets/public_hero.jpg);

		@media (max-width: 1150px) {

			.right {

				.details {

					p {
						font-size: 20px;
					}
				}
			}
		}

		@media (max-width: 950px) {

			.right {
				top: 50vh;
			}
		}

		@media (max-width: 750px) {

			.right {
				top: 0;
			}
		}
	}

	section.webapp {
		background: radial-gradient(ellipse at center, #6a2a97 0%, rgba(125, 185, 232, 0) 69%);
		overflow: hidden;

		.content {

			.left {
				display: flex;
				flex-direction: column;
				align-items: center;

				img {
					width: 50%;
				}
			}
		}

		img.webappui {
			height: 500px;

			@media (max-width: 1250px) {
				width: 100%;
				height: 100%;
			}
		}
	}

	section.kiosk {
		background: radial-gradient(ellipse at center, #6a2a97 0%, rgba(125, 185, 232, 0) 69%);

		.content {
			//top: -200px;
		}

		img.kiosk {
			height: 600px;
		}
	}

	section.notifications {
		margin-top: 250px;
		background: radial-gradient(ellipse at center, #6a2a97 0%, rgba(125, 185, 232, 0) 69%);

		img.notification {
			@media (max-width: 600px) {
				width: 50%;
				height: 50%;
				margin: auto;
			}
		}

		@media (max-width: 600px) {
			margin-top: 25%;
		}
	}

	section.iot {
		margin-top: 250px;
		background: radial-gradient(ellipse at center, #6a2a97 0%, rgba(125, 185, 232, 0) 69%);

		img.iot {
			@media (max-width: 600px) {
				width: 70%;
				height: 70%;
				margin: auto;
			}
		}

		@media (max-width: 600px) {
			margin-top: 25%;

			.content .hero {
				margin-bottom: 0;
			}
		}
	}

	@media (max-width: 600px) {
		padding-bottom: 50px;
	}
}