/*-----------------------------------------------------*\

	*Filename:		base.scss
	*Description: 	contains the baselines specifically
					for the project
	*Version:		1.0.0(2015-03-10)
	*Website:		http://schonne.com
	*Author:		Schonne Eldridge

 ===TABLE OF CONTENTS:===================================

	$_FONTS					dynamic fonts
	$_PROJECT BASELINE		baselines for the project suc

\*------------------------------------------------------*/

@import "sass";

/*-----------------------------------*\
		$_FONTS
\*-----------------------------------*/

@import url(https://fonts.googleapis.com/css?family=Ultra);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
@font-face {
	font-family: 'heartandsoul';
	src: url('/assets/fonts/heartandsoul-regular-webfont.woff2') format('woff2'),
		url('/assets/fonts/heartandsoul-regular-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'proximanova';
	src: url('/assets/fonts/Proxima-Nova-Regular.woff2') format('woff2'),
		url('/assets/fonts/Proxima-Nova-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'proximanova';
	src: url('/assets/fonts/Proxima-Nova-Bold.woff2') format('woff2'),
		url('/assets/fonts/Proxima-Nova-Bold.woff') format('woff');
	font-weight: bold;
	font-style: bold;
}

/*-----------------------------------*\
		$_PROJECT BASELINE
\*-----------------------------------*/

//body
body {
	background-color: black;
	overflow-x: hidden;
}

body, html, main {
	height: 100%;
}

	.content {
		display: flex;

		.left {
			width: 33%;
			padding-right: 5%;

			h1 {
				color: white;
				font-family: ultra;
				padding-bottom: 20px;
				margin-bottom: 20px;
			}

			p {
				font-size: 20px;
				color: #d8d8d8;
				font-family: proximanova;
				line-height: 1.6;
				text-align: justify;
			}
			@media (max-width: 600px) {
				padding-right: 0;
			}
		}

		.right {
			width: 67%;
			text-align: center;

			.details {
				display: flex;
				flex-wrap: wrap;
				border-left: 1px solid #666666;
				padding-left: 5%;
				text-align: left;

				p {
					font-size: 22px;
					color: #d8d8d8;
					font-family: proximanova;
					line-height: 1.6;
					text-align: justify;
					margin-bottom: 25px;
				}

				&:before {
					content: "";
					width: 10px;
					height: 10px;
					border: solid 1px #666666;
					display: block;
					border-radius: 10px;
					left: -6%;
					top: -9px;
				}

				&:after {
					content: "";
					display: block;
					position: absolute;
					left: -6px;
					bottom: -9px;
					width: 10px;
					height: 10px;
					border: solid 1px #666666;
					display: block;
					border-radius: 10px;
				}
			}
		}

		.hero {
			display: flex;

			.left {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				h1 {
					width: 100%;
					font-size: 30px;
					margin-bottom: 0;
				}

				p {
					font-size: 20px;
					margin-bottom: 18px;
				}
			}

			.right {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				.details {
					border: none;

					&:after, &:before {
						border: none;
					}
				}
			}
		}

		&.splainer {

			.left {

				img {
					width: 100%;
					border-radius: 10px;
					margin-top: 20%;
					box-shadow: 6px 6px 18px 0px rgba(0,0,0,0.75);
					cursor: pointer;
					transition: all .3s;

					&:hover {
						transition: all .7s;
						transform: scale(2.5,2.5) translatex(25%);
						z-index: 1;
						box-shadow: -9px 18px 25px 0 rgba(0, 0, 0, .3);
					}
				}

				p {
					font-size: 14px;
					color: #a7a7a7;
					text-transform: uppercase;
					text-align: center;
				}
			}

			.right {

				.details {
					display: block;

					h1 {
						font-size: 26px;
						font-family: ultra;
						letter-spacing: 1px;
						color: #a7a7a7;
						letter-spacing: 1px; 
						margin-bottom: 8px;
						margin-top: 100px;

						&:first-child {
							margin-top: 0;
						}
					}

					ul {
						margin-left: 5%;

						li {
							font-family: proximanova;
							color: #a7a7a7;
							font-size: 20px;
							list-style-type: circle;
							margin-bottom: 16px;
							line-height: 1.4;

							ul {
								margin-left: 8%;
								margin-top: 12px;
							}
						}
					}
				}
			}
		}

		@media (max-width: 1250px) {
			width: 90%;

			.hero {
					.left {

						h1 {
							font-size: 25px;
						}
						p {
							font-size: 18px;
						}
					}
			}
		}

		@media (max-width: 1020px) {

			.hero {
				flex-direction: column-reverse;
				margin-bottom: 50px;

				.left {
					width:100%;
				}

				.right {
					width: 100%;

					.details {
						padding-left: 0;
					}
				}
			}

			&.splainer {
				display: flex;
				flex-direction: column;

				.left {
					width: 100%;
					padding-right: 0;

					img {
						margin-top: 5%;

						&:hover {
							transform: inherit;
						}
					}
				}

				.right {
					width: 100%;
					margin-top: 50px;

					.details {
						border: 0;

						&:after, &:before {
							display: none;
						}
					}
				}
			}
		}

		@media (max-width: 950px) {
			.right {

				.details {

					p {
						font-size: 18px;
					}
				}
			}
		}

		@media (max-width: 750px) {
			flex-direction: column;
		}

		@media (max-width: 600px) {

			&.splainer .right {
				margin-top: 25px;
			}

			&.splainer .right .details, .right .details {
				padding-left: 0;

				h1 {
					margin-top: 30px;
					font-size: 24px;
					margin-bottom: 0;
				}
			}
		}
	}

	section.hero {
		background-repeat: no-repeat;
		background-size: cover;
		height: 100vh;
		background-color: #0a091c;
		background-position: bottom right;
		margin-bottom: 250px;


		.left {
			top: 20vh;
			padding-top: 100px;

			h1 {
				font-size: 40px;
				letter-spacing: 2px;
				border-bottom: white solid 3px;
			}

			ul {
				margin-top: 30px;

				li {
					font-family: proximanova;
					text-transform: uppercase;
					letter-spacing: 4px;
					font-size: 22px;
					color: #d1a2f9;
					cursor: pointer;
					transition: color .3s;
					margin-bottom: 20px;

					&:hover {
						color: darken(#d1a2f9, 15%);
					}

				}
			}
		}

		.right {
			top: 60vh;
		}

		@media (max-width: 750px) {
			margin-bottom: 75px;
			height: auto;
			background-size: contain;
			background-position: top;

			.content {
				padding-top: 40%;
			}

			.left {
				top: 0;
				margin-top: 20%;
				width: 80%;
				margin-right: 10%;
				margin-left: 10%;
				text-align: center;
				padding: 0;

				h1 {
					font-size: 30px;
				}

				ul li {
					font-size: 18px;
				}
			}

			.right {
				top: 0;
				margin-top: 50px;
				width: 100%;

				.details {
					border: none;

					&:before, &:after {
						display: none;
					}
				}
			}
		}
	}