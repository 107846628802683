admin-element {
	display: block;
	padding-bottom: 300px;
	background-color: #031d10;

	section.hero {
		background-image: url(../assets/admin_hero.jpg);
		margin-bottom: 0;
		background-color: #031d10;

		.content .left ul li {
			color: #579473;

			&:hover {
				color: darken(#579473, 15%);
			}
		}
	}

	section.resources {
		background: radial-gradient(ellipse at center, #579473 0%, rgba(125, 185, 232, 0) 69%);
		margin-top: 200px;

		img.magglass {
			height: 400px;
		}

		@media (max-width: 1000px) {
			img.magglass {
				width: 90%;
				height: 90%;
				margin: auto;
			}
		}

		@media (max-width: 600px) {
			margin-top: 50px;
		}
	}

	section.analytics {
		padding-top: 150px;
		background: radial-gradient(ellipse at center, #579473 0%, rgba(125, 185, 232, 0) 69%);

		@media (max-width: 1000px) {
			img.webappui {
				width: 90%;
				height: 90%;
				margin: auto;
			}
		}

		@media (max-width: 600px) {
			padding-top: 50px;
		}
	}

	@media (max-width: 600px) {
		padding-bottom: 50px;
	}
}