clerks-element {
	display: block;
	background-color: #030a1d;
	padding-bottom: 300px;

	section.hero {
		background-image: url(../assets/clerks_hero.jpg);
		margin-bottom: 0;

		.content .left ul li {
			color: #5aa585;

			&:hover {
				color: darken(#5aa585, 15%);
			}
		}
	}

	section.cms {
		margin-top: 200px;
		overflow: hidden;
		background: radial-gradient(ellipse at center, #3d7461 0%, rgba(125, 185, 232, 0) 69%);

		@media (max-width: 1000px) {
			img.webappui {
				width: 90%;
				height: 90%;
				margin: auto;
			}
		}

		@media (max-width: 600px) {
			margin-top: 50px;
		}
	}

	section.cs {
		padding-top: 150px;
		background: radial-gradient(ellipse at center, #3d7461 0%, rgba(125, 185, 232, 0) 69%);

		img.headset {
			height: 400px;
			margin-bottom: 50px;
		}

		@media (max-width: 600px) {
			padding-top: 50px;

			img.headset {
				width: 90%;
				height: 90%;
				margin: auto;
			}
		}
	}

	section.voice {
		padding-top: 150px;
		background: radial-gradient(ellipse at center, #3d7461 0%, rgba(125, 185, 232, 0) 69%);

		img.microphone {
			height: 400px;
			margin-bottom: 50px;
		}

		@media (max-width: 600px) {
			padding-top: 50px;

			.hero {
				margin-bottom: 0;
			}
		}
	}

	section.integrations {
		padding-top: 150px;
		background: radial-gradient(ellipse at center, #3d7461 0%, rgba(125, 185, 232, 0) 69%);

			@media (max-width: 600px) {
			padding-top: 50px;

			img.puzzle {
				width: 90%;
				height: 90%;
				margin: auto;
			}
		}
	}

	@media (max-width: 600px) {
		padding-bottom: 50px;
	}
}